import { store } from "@risingstack/react-easy-state";
import main from "./main";

function generateLicenseKey() {
  function getRandomCharacter() {
    const characters = "ABCDEFGHJKLMNPQRTUVWXYZ234679";
    return characters.charAt(Math.floor(Math.random() * characters.length));
  }

  let licenseKey = "";
  for (let i = 0; i < 2; i++) {
    let block = "";
    for (let j = 0; j < 5; j++) {
      block += getRandomCharacter();
    }
    licenseKey += `${block}-`;
  }

  let lastBlock = "";
  for (let j = 0; j < 5; j++) {
    lastBlock += getRandomCharacter();
  }
  licenseKey += lastBlock;

  return licenseKey;
}

const licenses = store({
  input: "",
  isLoading: false,
  status: "",
  licenseCount: "10",

  setInput(value: string) {
    licenses.input = value;
  },

  setLicenseCount(value: string) {
    licenses.licenseCount = value;
  },

  generateLicenses() {
    const count = Number.parseInt(licenses.licenseCount, 10);
    if (Number.isNaN(count) || count <= 0) {
      licenses.status = "Please enter a valid number of licenses to generate.";
      return;
    }

    const generatedKeys = Array.from({ length: count }, generateLicenseKey);
    licenses.input = generatedKeys.join("\n");
    licenses.status = `Generated ${count} license${count > 1 ? "s" : ""}.`;
  },

  async addLicenses() {
    try {
      licenses.isLoading = true;
      licenses.status = "Adding licenses...";

      const licenseList = licenses.input
        .split("\n")
        .filter((license) => license.trim() !== "");

      const response = await fetch(
        "https://n8n.trainyourears.com/webhook/add-licenses-endpoint", // Replace with actual endpoint
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: main.secret,
          },
          body: JSON.stringify({ licenses: licenseList }),
        },
      );

      const result = await response.json();

      if (response.ok) {
        licenses.status = `Successfully added ${result.addedCount} licenses.`;
        licenses.input = "";
      } else {
        throw new Error(result.message || "Failed to add licenses");
      }
    } catch (error) {
      licenses.status = `Error: ${error instanceof Error ? error.message : String(error)}`;
    } finally {
      licenses.isLoading = false;
    }
  },
});

export default licenses;
