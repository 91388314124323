import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text,
  Input,
  HStack,
} from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import licenses from "../../stores/licenses";

const AddLicenses = view(() => {
  return (
    <VStack spacing={4} align="stretch">
      <Box>
        <FormControl>
          <FormLabel>Number of Licenses to Generate</FormLabel>
          <HStack>
            <Input
              type="number"
              value={licenses.licenseCount}
              onChange={(e) => licenses.setLicenseCount(e.target.value)}
              width="100px"
            />
            <Button onClick={licenses.generateLicenses} colorScheme="green">
              Generate
            </Button>
          </HStack>
        </FormControl>
      </Box>

      <FormControl>
        <FormLabel>Licenses</FormLabel>
        <Textarea
          value={licenses.input}
          onChange={(e) => licenses.setInput(e.target.value)}
          placeholder="Generated licenses will appear here. You can also manually enter licenses."
          rows={10}
        />
      </FormControl>

      <Button
        colorScheme="blue"
        onClick={licenses.addLicenses}
        isLoading={licenses.isLoading}
      >
        Add Licenses
      </Button>

      {licenses.status && (
        <Box>
          <Text fontWeight="bold">Status:</Text>
          <Text>{licenses.status}</Text>
        </Box>
      )}
    </VStack>
  );
});

export default AddLicenses;
